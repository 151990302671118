import EN from "@/utils/translations/en.json"
import NL from "@/utils/translations/nl.json"
import ES from "@/utils/translations/es.json"
import PT from "@/utils/translations/pt.json"
import DE from "@/utils/translations/de.json"

import Head from "next/head"
import { useSession } from "next-auth/react"
import styled from "styled-components"
import Footer from "@/components/common/Layout/footer/Footer"
import fetchHead from "@/utils/fetchHead"
import Home from "./home/index"
import { useRouter } from "next-translate-routes"

function Homepage({ title, meta }) {
  const { data: session } = useSession()
  const router = useRouter()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" property="og:title" content={title} />
        <meta name="title" property="title" content={title} />
        <meta name="description" property="description" content={meta} />
        <meta name="og-description" property="og:description" content={meta} />
      </Head>
      <Wrapper>
        <Home session={session} />
      </Wrapper>
      <Footer />
    </>
  )
}

export default Homepage

const Wrapper = styled.div`
  width: 100%;
  min-height: 90vh;

  .headerBar {
    box-shadow: none;
    position: relative;
  }
`

export async function getStaticProps({ locale }) {
  try {
    // Define translations more directly
    const translations = {
      data: [
        {
          EN: EN,
          NL: NL,
          ES: ES,
          PT: PT,
          DE: DE,
        },
      ],
    }

    const title = await fetchHead(
      "head_title_homepage",
      translations,
      locale ? locale : "en"
    )
    const meta = await fetchHead(
      "head_meta_homepage",
      translations,
      locale ? locale : "en"
    )

    return {
      props: {
        title,
        meta,
      },
      // Revalidate every 24 hours
      revalidate: 60 * 60 * 24,
    }
  } catch (error) {
    console.log(error)
    return {
      notFound: true,
    }
  }
}
